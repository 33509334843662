import Amplify, { Auth } from 'aws-amplify';
import { awsmobile } from '../constants/AppSettings';

export default class AuthService {

  async initAmplify() {
    return Amplify.configure(awsmobile);
  }

  // this is the first step of the forgot password flow
  // forgotPassword will send a code to the email
  // then call userResponse = await Auth.forgotPasswordSubmit(user.email, user.code, user.confirmPassword);
  async forgotPassword({ username }) {
    return await Auth.forgotPassword(username);
  }

  async forgotPasswordUpdate({ username, code, newPassword }) {
    return await Auth.forgotPasswordSubmit(username, code, newPassword);
  }

  async currentCredentials() {
    return Auth.currentCredentials();
  }

  // this is the register first step
  async signUp({ username, password }) {
    // try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email: username
      }
    });
    console.log(user);
    return user;
    // } catch (error) {
    //   console.log('error signing up:', error);
    //   return null;
    // }
  }

  async resendSignUp({ username }) {
    const ret = await Auth.resendSignUp(username);
    return ret;
  }

  // you will receive a One Time Password when you sign up so this is to validate the 
  /// user email
  async confirmUser({ username, code }) {
    // try {
    return await Auth.confirmSignUp(username, code);
    // } catch (error) {
    //   console.log('error confirming sign up', error);
    //   throw error;
    // }
  }

  // after log in use  await Auth.currentCredentials(); to get the triplet of keys
  // this is the login
  async signIn({ username, password }) {
    // try {
    const user = await Auth.signIn(username, password);
    return user;
    // } catch (error) {
    //   console.log('error signing in', error);
    //   return null;
    // }
  }

  // this is to print the current user (debug puroposes)
  async currentSession() {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      const currentCreds = await Auth.currentCredentials();
      // const currentUserCreds = await Auth.currentUserCredentials();
      // const currentUserInfo = await Auth.currentUserInfo();
      const currentSession = await Auth.currentSession();

      return { session: currentSession, authUser, currentCreds };
    } catch (error) {
      console.log('error retrieving session info:', error);
      return null;
    }
  }

  // the logout
  async signOut() {
    try {
      return await Auth.signOut();
    } catch (error) {
      console.log('error confirming sign out', error);
      return null;
    }
  }


}