import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import LandingItem from "../../components/MembershipItem/landingItem";
import { useDispatch } from "react-redux";
// import { createPayment } from "../../stores/StripeSlice";
import { useLoader } from "../../providers/loader";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { getUserEmail, setUserEmail } from "../../helpers/user";
import { signOut, getSession } from "../../stores/AuthSlice";
import { notifyError } from "../../helpers/notifications";
import { SET_PROFILE } from "../../stores/UserSlice";
// import { changeLanguage } from "./../../helpers/locale";

const Landing = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openLoader, closeLoader } = useLoader();
  const [toggle, setToggle] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  // const onLangChange = (lang) => {
  //   changeLanguage(lang);
  // };

  const goToLogin = async () => {
    navigate("/login");
  };

  const goToProfile = async () => {
    navigate("/profile");
  }


  const activateToggle = () => {
    setToggle(!toggle);
  }


  // const redirectToStripeCheckout = async () => {
  //   // this should be retrieved from an api probably
  //   // const priceId = "price_1KlvUFD4rN8WDnbaUaPFVbmS";
  //   openLoader();
  //   try {
  //     const priceId = "price_1KnksrGL1YR91Jz8IWM9VmPW";
  //     await dispatch(createPayment({ price: priceId, quantity: 1 })).unwrap();
  //   } catch (e) {
  //     console.error(e);
  //   } finally {
  //     closeLoader();
  //   }

  // }

  const getAuthUser = useCallback(async () => {
    let authUsr = null;
    try {
      const { authUser } = await dispatch(getSession({})).unwrap();
      authUsr = authUser;
    } catch (e) {
      return null;
    } finally {
      setIsAuthenticated(authUsr);
    }
  }, [dispatch, getSession])

  const logout = async () => {
    try {
      openLoader();
      await dispatch(signOut({})).unwrap();
      dispatch(SET_PROFILE(null));
      setUserEmail("");

      await getAuthUser();
    } catch (e) {
      notifyError(e.message);
      console.error(e);
    }finally {
      closeLoader();
    }
  }

  useEffect(() => {
    getAuthUser();
  }, [getAuthUser])

  return (
    <React.Fragment>
      {isAuthenticated && <div className="d-flex justify-content-end">
        <Dropdown isOpen={toggle} toggle={activateToggle}>
          <DropdownToggle caret>
            {getUserEmail()}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Logged in</DropdownItem>
            <DropdownItem >Subscriptions</DropdownItem>
            <DropdownItem onClick={goToProfile}>Settings</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={logout}>Log Out</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>}
      <div className="text-end">
        {/* <Button onClick={() => onLangChange("en")}>en</Button>{" "}
        <Button onClick={() => onLangChange("it")}>it</Button> */}
        {/* <button className="btn btn-tertiary btn-sm" onClick={redirectToStripeCheckout}>
          {t("home.subnow")}
        </button>
        <button
          className="btn btn-outline-white btn-sm ms-1"
          onClick={redirectToStripeCheckout}
        >
          {t("home.signin")}
        </button> */}
      </div>
      <LandingItem></LandingItem>
    </React.Fragment>
  );
};

export default Landing;
