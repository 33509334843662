import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"

import reduxStore, { persistor as reduxPersistor } from "../../stores";

const ReduxProvider = ({ children }) => {
  return (
    <Provider store={reduxStore}>
      <PersistGate loading={null} persistor={reduxPersistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default ReduxProvider;
