import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import icon from "../../assets/icons/logo_with_name.png";
import "./membership.scss";
import { useDispatch } from "react-redux";
import { createPayment } from "../../stores/StripeSlice";
import { useLoader } from "../../providers/loader";

const LandingItem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openLoader, closeLoader } = useLoader();

  const goToLogin = () => {
    navigate("/login");
  };

  const redirectToStripeCheckout = async () => {
    // this should be retrieved from an api probably
    // const priceId = "price_1KlvUFD4rN8WDnbaUaPFVbmS";
    openLoader();
    try {
      const priceId = "price_1KnksrGL1YR91Jz8IWM9VmPW";
      await dispatch(createPayment({ price: priceId, quantity: 1 })).unwrap();
    } catch (e) {
      console.error(e);
    } finally {
      closeLoader();
    }

  }

  return (
    <div className="landing_card p-2">
      <img width="150" src={icon} alt="logo" />
      <div className="py-2 px-1">
        <p className="title">{t("home.title")}</p>
        <p className="subtitle">
          39€ {t("home.subtitle")} <del>99€</del>
          <span className="note"> {t("home.permonth")}</span>
        </p>
        <p className="note mb-1">{t("home.note")}</p>
        <Button
          variant="tertiary"
          className="text-wrap"
          size="lg"
          onClick={redirectToStripeCheckout}
        >
          {t("home.signup")}
        </Button>
      </div>
    </div>
  );
};

export default LandingItem;
