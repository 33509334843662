import React, { createContext, useContext, useState } from "react";
import { Spinner } from "reactstrap";
import "./../../assets/styles/_loader.scss";

const LoaderContext = createContext(false);

export const useLoader = () => {
  const { openLoader, closeLoader } = useContext(LoaderContext);

  return { openLoader, closeLoader };
};

const LoaderProvider = ({ children }) => {
  const openLoader = () => {
    setState({ ...state, isLoading: true });
  };

  const closeLoader = () => {
    setState({ ...state, isLoading: false });
  };

  const initState = {
    isLoading: false,
    openLoader,
    closeLoader,
  };

  const [state, setState] = useState(initState);

  return (
    <LoaderContext.Provider value={state}>
      <React.Fragment>
        <div
          id="loader"
          style={{
            display: state.isLoading ? "block" : "none",
          }}
        >
          <div id="status">
            <Spinner color="primary"></Spinner>
          </div>
        </div>

        {children}
      </React.Fragment>
    </LoaderContext.Provider>
  );
};

export default LoaderProvider;
