import AuthService from "./AuthService";
import StripeService from "./StripeService";
import UserService from "./UserService";
import ProductService from "./ProductService";


const services = {
  AuthService: new AuthService(),
  UserService: new UserService(),
  StripeService: new StripeService(),
  ProductService: new ProductService()
};

export default services;
