import toast from "react-hot-toast";
import i18n from "../i18n";

export function notifyError(error, button = "", onclick = null) {
  var message = error?.message || "Unknown error";
  switch (error?.code) {
    case "NotAuthorizedException":
      message = i18n.t("toast.error.loginFail");
      break;
    case "UsernameExistsException":
      message = i18n.t("toast.error.userExists");
      break;
    case "UserNotConfirmedException":
      message = i18n.t("toast.error.notConfirmed");
      break;
    case "InvalidPasswordException":
      if (error.message.includes("not long"))
        message = i18n.t("toast.error.shortPassword");
      else if (error.message.includes("must have uppercase"))
        message = i18n.t("toast.error.upperPassword");
      else if (error.message.includes("must have lowercase"))
        message = i18n.t("toast.error.lowerPassword");
      else if (error.message.includes("must have symbol"))
        message = i18n.t("toast.error.symbolsPassword");
      else message = i18n.t("toast.error.invalidPassword");
      break;
    case "UserNotFoundException":
      message = i18n.t("toast.error.userNotFound");
      break;
    case "ExpiredCodeException":
      message = i18n.t("toast.error.invalidVerifyCode");
      break;
    default:
      break;
  }
  toast.error(
    <div>
      <div>{message}</div>
      {button ? (
        <button className="btn btn-primary btn-sm mt-2" onClick={onclick}>
          {button}
        </button>
      ) : (
        ""
      )}
    </div>,
    {
      duration: 4000,
      position: "top-center",
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
        background: "#e5e7eb",
      },
    }
  );
}

export function notifySuccess(message) {
  toast.success(message, {
    duration: 4000,
    position: "top-center",
    style: {
      border: "1px solid #713200",
      padding: "16px",
      color: "#713200",
      background: "#e5e7eb",
    },
  });
}

export function notifyWarning(message) {
  toast(message, {
    icon: '⚠️',
    duration: 4000,
    position: "top-center",
    style: {
      border: "1px solid #713200",
      padding: "16px",
      color: "#713200",
      background: "#e5e7eb",
    },
  });
}

export function notify(message) {
  toast(message, {
    duration: 4000,
    position: "top-center",
    style: {
      border: "1px solid #713200",
      padding: "16px",
      color: "#713200",
      background: "#e5e7eb",
    },
  });
}

export function notifyPromise(callback, loading, messageSuccess, messageError) {
  toast.promise(callback(), {
    loading,
    success: messageSuccess,
    error: messageError,
  });
}
