import client from "../helpers/client";
import { api } from "../constants/AppSettings";
export default class UserService {

  async identifyUser({ credentials, payload }) {
    return new Promise(async (resolve, reject) => {
      /**
        name: "",
        surname: "",
        source: "",
        email: "",
        cognitoUsername: "",
        accessToken: ""
       */

      const awsClient = await client.newClient({
        accessKey: credentials.accessKeyId,
        secretKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: api.region
      });

      awsClient.identifyUser({}, payload, {}).then((res) => {
        resolve(res);
      }).catch(e => {
        reject(e);
      });
    });

  }

  async updateUser({ credentials, payload }) {
    return new Promise(async (resolve, reject) => {

      /**
        name: "",
        surname: "",
        phone: "",
        tax_id: "",
        address: "",
        city: "",
        zip_code: ""
       */
      const awsClient = await client.newClient({
        accessKey: credentials.accessKeyId,
        secretKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: api.region
      });

      awsClient.putUser({}, payload, {}).then((res) => {
        resolve(res);
      }).catch(e => {
        reject(e);
      })
    });
  }
}