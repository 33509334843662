import client from "../helpers/client";
import { api } from "../constants/AppSettings";

export default class UserService {

  async getProducts({ credentials, payload }) {
    return new Promise(async (resolve, reject) => {

      const awsClient = await client.newClient({
        accessKey: credentials.accessKeyId,
        secretKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
        region: api.region
      });

      awsClient.sendRequest('post', '/product/public/list', {}, payload, {}).then((res) => {
        resolve(res);
      }).catch(e => {
        reject(e);
      })
    });
  }

}