import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikForm from "../form/FormikForm";
import Form from "./../form/FormikFields";
import BaseCard from "../Card";
import { Col, Row, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCredentials, getSession /*signIn*/ } from "../../stores/AuthSlice";
import { identifyUser, putUser, SET_PROFILE } from "../../stores/UserSlice";
import { getProductKey, getUserEmail } from "../../helpers/user";
import { useLoader } from "../../providers/loader";
import { notifyError, notifySuccess } from "../../helpers/notifications";
import { getProducts } from "../../stores/ProductSlice";

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required("Email is required"),
  mobile: Yup.number().required(),
  address: Yup.string().required(),
  city: Yup.string().required(),
  postalCode: Yup.number().required(),
});

const RegisterForm = ({ profile, isAuthenticated }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { openLoader, closeLoader } = useLoader();
  // const { credentials } = useSelector(state => state.auth);
  const { userProfile } = useSelector((state) => state.user);

  const initialFormModel = {
    name: userProfile?.name || "",
    lastName: userProfile?.surname || "",
    email: getUserEmail(),
    mobile: userProfile?.phone || "",
    address: userProfile?.address || "",
    city: userProfile?.city || "",
    postalCode: userProfile?.zip_code || "",
  };

  const getRedirectUrl = async (productKey = 0) => {
    const creds = await dispatch(getCredentials({})).unwrap();
    const res = await dispatch(
      getProducts({ credentials: creds, payload: { id: +productKey } })
    ).unwrap();
    return res?.[0]?.return_url;
  };

  const handleRegistration = async (values) => {
    openLoader();
    try {
      // if (!profile) await dispatch(signIn({ email: credentials.email, password: credentials.password })).unwrap();

      const creds = await dispatch(getCredentials({})).unwrap();
      const { session } = await dispatch(getSession({})).unwrap();

      const cognitoUserId = session?.idToken?.payload?.sub;
      const payloadIdentify = {
        name: values.name,
        surname: values.lastName,
        source: "cognito",
        cognitoUsername: cognitoUserId,
      };

      // console.log('payloadIdentify: ', payloadIdentify);

      await dispatch(
        identifyUser({ credentials: creds, payload: payloadIdentify })
      ).unwrap();

      const payloadUpdate = {
        name: values?.name,
        surname: values?.lastName,
        phone: values?.mobile,
        address: values?.address,
        city: values?.city,
        zip_code: values?.postalCode,
      };

      // console.log('payloadUpdate: ', creds);

      await dispatch(
        putUser({ credentials: creds, payload: payloadUpdate })
      ).unwrap();

      if (!profile) {
        const redirectUrl = await getRedirectUrl(getProductKey());
        if (redirectUrl) {
          window.open(redirectUrl, "_self");
        } else {
          navigate("/product-registration-complete");
        }
      } else {
        notifySuccess("Information Updated!");
      }

      const userProf = await dispatch(
        identifyUser({ credentials: creds, payload: payloadIdentify })
      ).unwrap();
      dispatch(SET_PROFILE(userProf.user));
    } catch (e) {
      notifyError(e);
    } finally {
      closeLoader();
    }
  };

  useEffect(() => {}, [userProfile]);

  return (
    <>
      <BaseCard headerLine={false} style={{ maxWidth: "500px" }}>
        <Trans
          t={t}
          i18nKey="registration.title"
          components={{
            br: <br />,
            strong: <strong></strong>,
          }}
        />
        <p className="registration_and_log_in_title pt-2">
          {profile ? "Account Information" : t("registration.newAccount")}
        </p>
        <FormikForm
          initialValues={initialFormModel}
          validationSchema={validationSchema}
          onSubmit={handleRegistration}
        >
          <Row>
            <Col className="col-sm-6 col-12">
              <Form.TextInput
                name="name"
                placeholder={t("registration.name")}
                disabled={!isAuthenticated && profile}
              />
            </Col>
            <Col className="col-sm-6 col-12">
              <Form.TextInput
                name="lastName"
                placeholder={t("registration.surname")}
                disabled={!isAuthenticated && profile}
              />
            </Col>
          </Row>

          <Form.TextInput
            name="email"
            placeholder="Email"
            disabled={!isAuthenticated && profile}
          />

          <Form.TextInput
            name="mobile"
            placeholder={t("registration.phone")}
            disabled={!isAuthenticated && profile}
          />

          <p className="registration_and_log_in_title text-start">
            {t("registration.address")}
          </p>

          <Form.TextInput
            name="address"
            placeholder={t("registration.residencAddress")}
            disabled={!isAuthenticated && profile}
          />

          <Row>
            <Col className="col-sm-6 col-12">
              <Form.TextInput
                name="city"
                placeholder={t("registration.city")}
                disabled={!isAuthenticated && profile}
              />
            </Col>
            <Col className="col-sm-6 col-12">
              <Form.TextInput
                name="postalCode"
                placeholder={t("registration.cap")}
                disabled={!isAuthenticated && profile}
              />
            </Col>
          </Row>

          <Button
            block
            size="lg"
            color="primary"
            type="submit"
            className="text-white fw-bold"
            disabled={!isAuthenticated && profile}
          >
            {profile ? "Update" : t("registration.register")}
          </Button>
        </FormikForm>
      </BaseCard>
    </>
  );
};

export default RegisterForm;
