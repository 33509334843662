import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import * as Yup from "yup";
import FormikForm from "./../../components/form/FormikForm";
import Form from "./../../components/form/FormikFields";
import BaseCard from "../../components/Card";
import { useDispatch } from "react-redux";
import { forgotPassword, updatePassword } from "../../stores/AuthSlice";
import { useNavigate } from "react-router";
import { useLoader } from "../../providers/loader";
import { notifyError, notifySuccess } from "../../helpers/notifications";

const ForgotPassword = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openLoader, closeLoader } = useLoader();
  const [step, setStep] = useState(0);

  const formModel = {
    email: "",
    codeInput: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is required"),
    codeInput: Yup.string().matches(/^[0-9]+$/).min(6, "Must be 6 digits").max(6, "Must be 6 digits").test('code-test', 'Valid code is required', function test(val) {
      var matched = val?.match(/^[0-9]+$/);
      if (step !== 1) {
        return true;
      } else {
        if (val && (matched.input.length === 6)) {
          return true;
        } else {
          return false;
        }
      }
    }),
    password: Yup.string().test('password-test', 'Password is required', function test(val) {
      if (step !== 1) {
        return true;
      } else {
        if (val && (val.length > 0)) {
          return true;
        } else {
          return false;
        }
      }
    })
  });


  const handleRecovery = async (values) => {
    values.email = values.email.trim().toLowerCase();
    openLoader();
    try {
      switch (step) {
        case 0:
          await dispatch(forgotPassword({ email: values.email })).unwrap();
          setStep(1);
          break;
        case 1:
          await dispatch(updatePassword({ email: values.email, code: values.codeInput, password: values.password })).unwrap();
          notifySuccess('Password updated!');
          navigate("/login");
          break;
        default:
          throw new Error("bad state");
      }

    } catch (e) {
      console.error(e);
      // should be set to either 0 or 1 depending on what call failed
      setStep(1);
      notifyError(`Could not update password: ${e.message}`);
    } finally {
      closeLoader();
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <BaseCard headerLine={false} headerText="Enter your email to start the recovery process">


            <FormikForm
              initialValues={formModel}
              validationSchema={validationSchema}
              onSubmit={handleRecovery}
            >
              <div className="d-flex justify-content-center">
                <div className="pt-3">
                  <Form.TextInput name="email" placeholder="Email address"></Form.TextInput>

                  <Form.TextInput hidden={step !== 1} name="codeInput" placeholder="6 digit code"></Form.TextInput>
                  <Form.PasswordInput
                    hidden={step !== 1}
                    name="password"
                    placeholder="New password"
                  />

                  <Button type="sumbit" block color="accent">
                    Confirm
                  </Button>
                </div>
              </div>

            </FormikForm>
          </BaseCard>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ForgotPassword;