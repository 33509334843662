
export const setUserEmail = (email) => {
  localStorage.setItem('USER_EMAIL', email);
}

//CognitoIdentityServiceProvider.4u7gorird2g0d6u78gja2q37aj.LastAuthUser
export const getUserEmail = () => {
  return localStorage.getItem('USER_EMAIL');
}

export const setProductKey = (productKey) => {
  localStorage.setItem('PRODUCT_KEY', productKey);
}

export const getProductKey = () => {
  return localStorage.getItem('PRODUCT_KEY');
}