import { loadStripe } from "@stripe/stripe-js";
import { stripe } from "../constants/AppSettings";

export default class StripeService {

  constructor() {
    this.stripePromise = loadStripe(stripe.publicKey);
  }
  
  async goToCheckout({ price, quantity = 1 }) {
    return (await this.stripePromise).redirectToCheckout({
      mode: 'subscription',
      lineItems: [{ price, quantity }],
      successUrl: `${window.location.href}login`,
      cancelUrl: `${window.location.href}failure`
    });
  }

}