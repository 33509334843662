import React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import icon from "../../assets/icons/example_membership.svg";

const BaseCard = ({
  headerText,
  headerLine = true,
  style,
  className,
  cardClass,
  children
}) => {
  return (
    <div className={className}>
      <Card style={{ ...style }} className={cardClass}>
        <CardBody>
          <CardTitle
            className={
              "pb-1 text-black text-center " +
              (headerLine ? "membership_title" : "card_title")
            }
          >
            {headerLine && <img src={icon} alt="logo" />}
            {headerText}
          </CardTitle>
          {headerLine && (
            <div
              style={{
                borderBottom: "1px solid rgba(60,60,60,0.2)",
                width: "50%",
                marginLeft: "25%"
              }}
            ></div>
          )}
          <CardText>{children}</CardText>
        </CardBody>
      </Card>
    </div>
  );
};

export default BaseCard;
