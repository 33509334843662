import React from "react";

const NotFound = () => {


  return (
    <React.Fragment>
      <p>Page not found</p>
    </React.Fragment>
  )
}

export default NotFound;