

export const routes = {
  home: {
    path: "/",
  },
  unmatched: {
    path: "*",
  },
  landing: {
    path: "/landing"
  },
  failure: {
    path: "/failure"
  },
  login: {
    path: "/login"
  },
  registration: {
    path: "/registration"
  },
  emailConfirmation: {
    path: "/email-confirmation"
  },
  forgotPassword: {
    path: "/forgot-password"
  },
  productRegistration: {
    path: "/product-registration-complete"
  },
  profile: {
    path: "/profile"
  },
  notFound: {
    path: "/not-found",
  },
}