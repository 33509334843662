import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import RegisterForm from "../../components/Register/RegisterForm";
import { useLoader } from "../../providers/loader";
import { getSession, signOut } from "../../stores/AuthSlice";
import { notifyError } from "../../helpers/notifications";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from "react-router";
import { getUserEmail, setUserEmail } from "../../helpers/user";
import { SET_PROFILE } from "../../stores/UserSlice";


const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openLoader, closeLoader } = useLoader();
  const [toggle, setToggle] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const goToProfile = async () => {
    navigate("/profile");
  }

  const activateToggle = () => {
    setToggle(!toggle);
  }

  const getAuthUser = useCallback(async () => {
    let authUsr = null;
    try {
      const { authUser } = await dispatch(getSession({})).unwrap();
      authUsr = authUser;
    } catch (e) {
      return null;
    } finally {
      setIsAuthenticated(authUsr);
    }
  }, [dispatch, getSession])

  const logout = async () => {
    try {
      openLoader();
      await dispatch(signOut({})).unwrap();
      dispatch(SET_PROFILE(null));
      setUserEmail("");
      await getAuthUser();
    } catch (e) {
      notifyError(e.message);
      console.error(e);
    } finally {
      closeLoader();
      navigate("/");
    }
  }

  useEffect(() => {
    getAuthUser();
  }, [getAuthUser])

  return (
    <React.Fragment>
      {!isAuthenticated && <div className="d-flex justify-content-center h1" style={{color: "brown"}}>Not logged in</div>}
      {isAuthenticated && <div className="d-flex justify-content-end">
        <Dropdown isOpen={toggle} toggle={activateToggle}>
          <DropdownToggle caret>
            {getUserEmail()}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Logged in</DropdownItem>
            <DropdownItem onClick={goToProfile}>Subscriptions</DropdownItem>
            <DropdownItem>Settings</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={logout}>Log Out</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>}
      <Row>
        <Col className="col-12 p-2">
        </Col>
        <Col className="col-12">
          <div className="d-flex justify-content-center">
            <RegisterForm isAuthenticated={isAuthenticated} profile={true} />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Profile;