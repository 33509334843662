import { combineReducers, configureStore } from "@reduxjs/toolkit";

import userReducer from "./UserSlice";
import authReducer from "./AuthSlice";
import stripeReducer from "./StripeSlice";
import productReducer from "./ProductSlice";

import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const storePersistKeys = {
  user: "skillsland_user"
};

const reducer = combineReducers({
  user: persistReducer({ key: storePersistKeys.user, storage }, userReducer),
  auth: authReducer,
  stripe: stripeReducer,
  product: productReducer
});

const rootReducer = (state, action) => {
  if (action.type === "store/reset") {
    state = undefined;
  }
  return reducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export const resetPersistor = () => {
  persistStore(store);
};

export default store;
