import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../services";

export const getCredentials = createAsyncThunk(
  "auth/credentials",
  async ({ ...props }, { rejectWithValue }) => {
    try {
      const response = await services.AuthService.currentCredentials();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSession = createAsyncThunk(
  "auth/getSession",
  async ({ ...props }, { rejectWithValue }) => {
    try {
      const response = await services.AuthService.currentSession();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await services.AuthService.signIn({ username: email, password });
      const creds = await services.AuthService.currentCredentials();
      return { user: response, creds };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await services.AuthService.signUp({ username: email, password });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async ({ ...props }, { rejectWithValue }) => {
    try {
      const response = await services.AuthService.signOut({});
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const resendSignUp = createAsyncThunk(
  "auth/resendSignUp",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await services.AuthService.resendSignUp({ username: email });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const confirmUser = createAsyncThunk(
  "auth/confirmUser",
  async ({ email, code }, { rejectWithValue }) => {
    try {
      const response = await services.AuthService.confirmUser({ username: email, code });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await services.AuthService.forgotPassword({ username: email });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "auth/updatePassword",
  async ({ email, code, password }, { rejectWithValue }) => {
    try {
      const response = await services.AuthService.forgotPasswordUpdate({ username: email, code, newPassword: password });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  error: {},
  state: "",
  credentials: {},
  currentUser: null,
  userProfile: {}
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SET_CREDENTIALS(state, action) {
      state.credentials = action.payload;
    }
  },
  extraReducers: {
    /* ---- Credentials ---- */
    [String(getCredentials.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getCredentials.fulfilled)]: (state, action) => {
      state.state = "success";
      // state.credentials = action.payload;
    },
    [String(getCredentials.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- Session ---- */
    [String(getSession.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getSession.fulfilled)]: (state, action) => {
      state.state = "success";
      // state.credentials = action.payload;
    },
    [String(getSession.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- Sign In ---- */
    [String(signIn.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(signIn.fulfilled)]: (state, action) => {
      state.state = "success";
      // state.credentials = action.creds;
      state.currentUser = action.user;
    },
    [String(signIn.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- Sign Up ---- */
    [String(signUp.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(signUp.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(signUp.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- Forgot Password ---- */
    [String(forgotPassword.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(forgotPassword.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(forgotPassword.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- Forgot Password Update ---- */
    [String(updatePassword.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(updatePassword.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(updatePassword.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- Resend registration code ---- */
    [String(resendSignUp.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(resendSignUp.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(resendSignUp.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- Sign out ---- */
    [String(signOut.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(signOut.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(signOut.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export const { SET_CREDENTIALS } = authSlice.actions;

export default authSlice.reducer;
