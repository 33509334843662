import { Button, Card, CardTitle, CardBody } from "reactstrap";
import appleStore from "./../../assets/icons/apple_download.svg";
import androidStore from "./../../assets/icons/android_download.svg";
import "./membership.scss";

const DownloadItem = () => {
  const handleStore = (store) => {
    if (store === "apple")
      window.location.href =
        "https://apps.apple.com/app/skillsland/id1499008065";
    else if (store === "android")
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.evoluzionepersonale.app";
  };

  return (
    <Card className="download-item text-white text-center mt-3">
      <CardBody className="download_background">
        <CardTitle className="title text-start">
          Accedi o scarica l'app di SkillsLand
          <br /> per visualizzare i tuoi nuovi contenuti.
        </CardTitle>
        <div className="text-end">
          <Button
            className="p-0 mt-1"
            onClick={() => {
              handleStore("apple");
            }}
          >
            <img src={appleStore} alt="download for apple" />
          </Button>
          <Button
            className="p-0 mt-1 ms-1"
            onClick={() => {
              handleStore("android");
            }}
          >
            <img src={androidStore} alt="download for android" />
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default DownloadItem;
