import React from "react";
import Routes from "./routes";

import AuthService from "./services/AuthService"
import { Toaster } from 'react-hot-toast';

const auth = new AuthService();
auth.initAmplify();

function App() {
  return (
    <React.Fragment>
      <Toaster />
      <Routes />
    </React.Fragment>
  );
}

export default App;
