import React from "react";
import BaseCard from "../../components/Card";

const PaymentFailed = () => {


  return (
    <React.Fragment>
      <BaseCard headerText="Payment failed" headerLine={false}>
        <div className="text-center">
          Because of reasons
        </div>
      </BaseCard>
    </React.Fragment>
  )
}

export default PaymentFailed;