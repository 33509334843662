import { Navigate, Route, Routes as ReactRouterRoutes } from "react-router-dom";
import { routes } from "../constants/routes";
import Login from "../pages/Login";
import Registration from "../pages/Registration";
// import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import PublicLayout from "../layouts/Public";
import Landing from "../pages/Landing";
import ProductRegistered from "../pages/ProductRegistered";
import EmailConfirmation from "../pages/Registration/ConfirmEmail";
import PaymentFailed from "../pages/PaymentFailed";
import ForgotPassword from "../pages/ForgotPassword";
import Profile from "../pages/Profile";

const Routes = () => {

  return (
    <ReactRouterRoutes>
      <Route element={<PublicLayout className="justify-content-left align-items-start landing_background" />}>
        <Route path={routes.home.path} element={<Landing />} />

        <Route path={routes.landing.path} element={<Landing />} />

        <Route path={routes.profile.path} element={<Profile />} />

      </Route>

      <Route element={<PublicLayout className="justify-content-center align-items-center" />}>
        <Route path={routes.login.path} element={<Login />} />

        <Route path={routes.registration.path} element={<Registration />} />

        <Route path={routes.productRegistration.path} element={<ProductRegistered />} />

        <Route path={routes.emailConfirmation.path} element={<EmailConfirmation />} />

        <Route path={routes.failure.path} element={<PaymentFailed />} />

        <Route path={routes.forgotPassword.path} element={<ForgotPassword />} />

      </Route>

      <Route path={routes.notFound.path} element={<NotFound />} />

      <Route
        path={routes.unmatched.path}
        element={<Navigate to={routes.notFound.path} replace={true} />}
      />

    </ReactRouterRoutes>
  )
}

export default Routes;