import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BaseCard from "../Card";
import { Button } from "reactstrap";
// import appleIcon from "../../assets/icons/apple_icon.svg";
// import fbIcon from "../../assets/icons/facebook_icon.svg";
// import googleIcon from "../../assets/icons/google_icon.svg";
import * as Yup from "yup";
import FormikForm from "../form/FormikForm";
import Form from "./../form/FormikFields";
import { useDispatch } from "react-redux";
import {
  signIn,
  signUp,
  getCredentials,
  getSession,
  signOut,
  SET_CREDENTIALS,
} from "../../stores/AuthSlice";
import { setUserEmail } from "../../helpers/user";
import { identifyUser, SET_PROFILE } from "../../stores/UserSlice";
import { useLoader } from "../../providers/loader";
import { notifyError, notifySuccess, notifyWarning } from "../../helpers/notifications";
import { getProducts } from "../../stores/ProductSlice";

const formModel = {
  email: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const LoginForm = ({ existingUser, productKey }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { openLoader, closeLoader } = useLoader();

  const checkIfMissingProfile = async (values) => {
    try {
      await dispatch(
        signIn({ email: values.email, password: values.password })
      ).unwrap();

      const creds = await dispatch(getCredentials({})).unwrap();
      const { session } = await dispatch(getSession({})).unwrap();

      const cognitoUserId = session?.idToken?.payload?.sub;

      const payloadIdentify = {
        source: "cognito",
        cognitoUsername: cognitoUserId,
      };

      const profile = await dispatch(
        identifyUser({ credentials: creds, payload: payloadIdentify })
      ).unwrap();
      console.log('profileee', profile.user);
      dispatch(SET_PROFILE(profile.user));

      if (!profile?.user?.name && !profile?.user?.surname) {
        // present a message to inform the user that their profile is missing
        // redirect user to provider their missing information
        notifyWarning(t("toast.missingProfileInfo"));
        navigate("/registration");
      } else {
        // show error message and inform the user they have
        // already completed the registration process with this account
        notifySuccess(t("toast.success.alreadyRegistered"));
        window.open('https://link.skillsland.it');
      }
    } catch (e) {
      if (e.code === "UserNotConfirmedException") {
        notifyError(e, t("toast.error.verifyMail"), () => {
          navigate("/email-confirmation");
        });
      } else if (e.code === "NotAuthorizedException") {
        notifyError(e, t("login.form.forgetPassword"), () => {
          navigate("/forgot-password");
        });
      } else {
        notifyError(e);
      }
    }
  }

  const handleLoginOption = async (values) => {
    values.email = values.email.trim().toLowerCase();
    openLoader();
    try {
      await dispatch(signOut({})).unwrap();
      dispatch(SET_PROFILE(null));
      setUserEmail("");

      dispatch(
        SET_CREDENTIALS({ email: values.email, password: values.password })
      );

      setUserEmail(values.email);
      await dispatch(
        signUp({ email: values.email, password: values.password })
      ).unwrap();
      // console.log("new user", res);
      navigate("/email-confirmation");
      // }
    } catch (e) {
      if (e.code === "UserNotConfirmedException") {
        notifyError(e, t("toast.error.verifyMail"), () => {
          navigate("/email-confirmation");
        });
      } else if (e.code === "NotAuthorizedException") {
        notifyError(e, t("login.form.forgetPassword"), () => {
          navigate("/forgot-password");
        });
      } else if (e.code === "UsernameExistsException") {
        // user has either completed the flow and is messing with us or
        // he is trying to register again because he didn't finish his personal details
        await checkIfMissingProfile(values);
      } else {
        notifyError(e);
      }
    } finally {
      closeLoader();
    }
  };

  return (
    <BaseCard headerLine={false} style={{ maxWidth: "500px" }}>
      <p className="registration_and_log_in_title">
        {existingUser
          ? t("login.form.loginTitle")
          : t("login.form.registerTitle")}
      </p>
      {existingUser ? "" : <p>{t("login.form.reminder")}</p>}
      <FormikForm
        initialValues={formModel}
        validationSchema={validationSchema}
        onSubmit={handleLoginOption}
      >
        <Form.TextInput name="email" label="Email" placeholder="Email" />

        <Form.PasswordInput
          name="password"
          label="Password"
          placeholder="Password"
        />

        <Button
          block
          type="submit"
          className={
            "text-white fw-bold " +
            (existingUser ? "register_btn" : "log_in_btn")
          }
        >
          {existingUser
            ? t("login.form.loginButton")
            : t("login.form.registerButton")}
        </Button>

        {/* <p className="pt-2 text-center">or</p> */}
      </FormikForm>

      <div className="d-grid gap-2">
        {/* <Button outline block color="primary" className="social_button fw-bold">
          <img
            src={googleIcon}
            alt="Google icon"
            style={{ maxWidth: "20px" }}
          />
          {existingUser ? "Continue" : "Sign Up"} with Google
        </Button>
        <Button outline block color="primary" className="social_button fw-bold">
          <img src={appleIcon} alt="Apple icon" style={{ maxWidth: "20px" }} />
          {existingUser ? "Continue" : "Sign Up"} with Apple
        </Button>
        <Button outline block color="primary" className="social_button fw-bold">
          <img src={fbIcon} alt="Facebook icon" style={{ maxWidth: "20px" }} />
          {existingUser ? "Continue" : "Sign Up"} with Facebook
        </Button> */}
        <div className="text-center">
          <Link to="/forgot-password">{t("login.form.forgetPassword")}</Link>
        </div>
      </div>
    </BaseCard>
  );
};

export default LoginForm;
