import React from "react";
import { Outlet } from "react-router";
import { Container } from 'reactstrap';

const PublicLayout = ({ className }) => {
  return (
    <React.Fragment>
      <div className={"p-4 d-flex " + className} style={{ minHeight: "400px" }}>
        <Container>
          <Outlet />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PublicLayout;