const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USER_POOL_ID,
  REACT_APP_AWS_IDENTITY_POOL_ID,
  REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_AWS_AUTHENTICATION_FLOW_TYPE,
  REACT_APP_AWS_API_GATEWAY,
  REACT_APP_STRIPE_PUBLIC_KEY
} = process.env;

const app = {
  environment: REACT_APP_ENVIRONMENT
}

const api = {
  url: REACT_APP_AWS_API_GATEWAY || "https://41vtym4ns0.execute-api.eu-west-1.amazonaws.com",
  region: REACT_APP_AWS_REGION || 'eu-west-1'
}

const stripe = {
  publicKey: REACT_APP_STRIPE_PUBLIC_KEY || "pk_test_gzRiRW2IU65rtWgS9NsF8cyx00AkjvGc54"
}

const awsmobile = {
  Auth: {
    region: REACT_APP_AWS_REGION || 'eu-west-1',
    userPoolId: REACT_APP_AWS_USER_POOL_ID || 'eu-west-1_8ZUGDHMzv',
    identityPoolId: REACT_APP_AWS_IDENTITY_POOL_ID || 'eu-west-1:72a423e8-d614-4072-a365-88dd5b4cf688',
    userPoolWebClientId: REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID || '4u7gorird2g0d6u78gja2q37aj',
    authenticationFlowType: REACT_APP_AWS_AUTHENTICATION_FLOW_TYPE || 'USER_PASSWORD_AUTH'
  }
}

console.log(app, api, stripe, awsmobile);

export { app, api, stripe, awsmobile }