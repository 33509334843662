import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import BaseCard from "../../components/Card";
import LoginForm from "../../components/Login/LoginForm";
import RecognitionItem from "../../components/MembershipItem/recognitionItem";
import { setProductKey } from "../../helpers/user";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const [userState, setUserState] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line

  const updateState = (event, state) => {
    event.preventDefault();
    if(state === 2) {
      window.open('https://link.skillsland.it');
      return;
    }
    setUserState(state);
  };

  useEffect(() => {
    setProductKey(searchParams.get("p"));
  }, [searchParams]);

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 p-2">
          <RecognitionItem productKey={searchParams.get("p")} />
        </Col>
        <Col className="col-12 p-2">
          <BaseCard headerText={t("login.account")} headerLine={false}>
            <div className="d-flex justify-content-center gap-4 pt-3">
              <Button
                size="sm"
                className="login_button_new"
                onClick={(e) => updateState(e, 1)}
              >
                {t("login.registrerButton")}
              </Button>
              <Button
                size="sm"
                className="login_button_existing"
                onClick={(e) => updateState(e, 2)}
              >
                {t("login.loginButton")}
              </Button>
            </div>
          </BaseCard>
        </Col>
        <Col className="col-12 pt-2">
          <div className="d-flex justify-content-center">
            {(userState === 1 || userState === 2) && (
              <LoginForm
                productKey={searchParams.get("p")}
                existingUser={userState === 2 ? true : false}
              />
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Login;
