import React from "react";
import { Col, Row } from "reactstrap";
import RecognitionItem from "../../components/MembershipItem/recognitionItem";
import RegisterForm from "../../components/Register/RegisterForm";

const Registration = () => {


  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 p-2">
          <RecognitionItem />
        </Col>
        <Col className="col-12">
          <div className="d-flex justify-content-center">
            <RegisterForm />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Registration;