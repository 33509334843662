import { Col, Row, Card, CardBody } from "reactstrap";
import profilePic from "./../../assets/icons/skillsland_logo.svg";
import tick from "./../../assets/icons/check.svg";
import "./membership.scss";
import { getUserEmail } from "../../helpers/user";

const CompleteItem = () => {

  const userEmail = getUserEmail();
  
  return (
    <Card className="complete-item text-white text-center mt-3">
      <CardBody className="py-4">
        <Row>
          <Col className="img-col">
            <img src={tick} alt="confirmed"></img>
          </Col>
          <Col className="text-start">
            <Row className="flex-column">
              <Col>
                The product has been registered <br />
                for the following account:
              </Col>
              <Col className="pt-3">
                <Row className="justify-content-center align-items-center">
                  <div className="d-flex align-items-center profile-container">
                    <div className="profile_picture">
                      <img src={profilePic} alt="profile" />
                    </div>
                    <div className="ps-1 email-text">{userEmail}</div>
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CompleteItem;
