import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";
import RecognitionItem from "../../components/MembershipItem/recognitionItem";
import * as Yup from "yup";
import FormikForm from "./../../components/form/FormikForm";
import Form from "./../../components/form/FormikFields";
import BaseCard from "../../components/Card";
import { useDispatch, useSelector } from "react-redux";
import { confirmUser, resendSignUp, signIn } from "../../stores/AuthSlice";
import { getUserEmail } from "../../helpers/user";
import { useNavigate } from "react-router";
import { useLoader } from "../../providers/loader";
import { notifyError, notifySuccess } from "../../helpers/notifications";

const Registration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openLoader, closeLoader } = useLoader();
  const { credentials } = useSelector((state) => state.auth);

  const formModel = {
    codeInput: "",
  };

  const validationSchema = Yup.object().shape({
    codeInput: Yup.string()
      .matches(/^[0-9]+$/, t("emailConfirmation.match"))
      .min(6, t("emailConfirmation.limit"))
      .max(6, t("emailConfirmation.limit"))
      .required(t("emailConfirmation.required")),
  });

  const resendCode = async () => {
    await dispatch(resendSignUp({ email: getUserEmail() })).unwrap();
  };

  const handleVerification = async (values) => {
    openLoader();
    try {
      await dispatch(
        confirmUser({ email: getUserEmail(), code: values.codeInput })
      ).unwrap();
      // if credentials have not been set we lost state bacause the user closed the browser
      if (credentials)
        await dispatch(
          signIn({ email: credentials.email, password: credentials.password })
        ).unwrap();
      // should we do something else here if there are no credentials?
      notifySuccess(t("toast.success.confirmUser"));
      navigate("/registration");
    } catch (e) {
      notifyError(e);
    } finally {
      closeLoader();
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12 p-2">
          <RecognitionItem />
        </Col>
        <Col className="col-12">
          <BaseCard
            headerLine={false}
            headerText={
              <Trans
                t={t}
                i18nKey="emailConfirmation.title"
                components={{
                  br: <br />,
                }}
              />
            }
          >
            <FormikForm
              initialValues={formModel}
              validationSchema={validationSchema}
              onSubmit={handleVerification}
            >
              <div className="d-flex justify-content-center">
                <div className="pt-3">
                  <Form.TextInput
                    name="codeInput"
                    placeholder={t("emailConfirmation.code")}
                  ></Form.TextInput>
                  <Button type="sumbit" block color="accent">
                    {t("emailConfirmation.confirm")}
                  </Button>
                  <div className="p-2">
                    <Button block outline onClick={resendCode}>
                      {t("emailConfirmation.moreCode")}
                    </Button>
                  </div>
                </div>
              </div>
            </FormikForm>
          </BaseCard>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Registration;
