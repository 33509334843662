import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../services";


export const createPayment = createAsyncThunk(
  "stripe/createPayment",
  async ({ price, quantity }, { rejectWithValue }) => {
    try {
      const response = await services.StripeService.goToCheckout({price, quantity});
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  error: {},
  state: "",
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
  },
  extraReducers: {
    /* ---- Identify stripe ---- */
    [String(createPayment.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createPayment.fulfilled)]: (state, action) => {
      state.currentstripe = action.payload.body.data;
      state.state = "success";
    },
    [String(createPayment.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export default stripeSlice.reducer;
