import apiGatewayClient from "./apiGatewayClient";
import sigV4Client from "./sigV4Client";
import simpleHttpClient from "./simpleHttpClient";
import utils from "./utils";

var apiGateway = {};
apiGateway.core = {};

apiGateway.core = apiGatewayClient.core;
apiGateway.core = { ...apiGateway.core, ...sigV4Client.core };
apiGateway.core = { ...apiGateway.core, ...simpleHttpClient.core };
apiGateway.core = { ...apiGateway.core, ...utils.core };

export default apiGateway;