import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../services";


export const identifyUser = createAsyncThunk(
  "user/identifyUser",
  async ({ credentials, payload }, { rejectWithValue }) => {
    try {
      const response = await services.UserService.identifyUser({ credentials, payload });
      return response?.data?.Data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const putUser = createAsyncThunk(
  "user/putUser",
  async ({ credentials, payload }, { rejectWithValue }) => {
    try {
      const response = await services.UserService.updateUser({ credentials, payload });
      return response?.data?.Data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  error: {},
  state: "",
  userId: "",
  currentUser: null,
  userProfile: {}
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    SET_USER(state, action) {
      state.currentUser = action.payload;
    },
    SET_PROFILE(state, action) {
      state.userProfile = action.payload;
    }
  },
  extraReducers: {
    /* ---- Identify User ---- */
    [String(identifyUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(identifyUser.fulfilled)]: (state, action) => {
      state.currentUser = action.payload;
      state.state = "success";
    },
    [String(identifyUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- Put User ---- */
    [String(putUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(putUser.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(putUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export const { SET_USER, SET_PROFILE } = userSlice.actions;

export default userSlice.reducer;
