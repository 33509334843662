import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import { useLoader } from "../../providers/loader";
import { getCredentials } from "../../stores/AuthSlice";
import { getProducts } from "../../stores/ProductSlice";
import "./membership.scss";

const RecognitionItem = ({ productKey }) => {
  const dispatch = useDispatch();
  const { openLoader, closeLoader } = useLoader();
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState("");

  const getItemDetails = useCallback(
    async (productKey = 0) => {
      openLoader();
      const creds = await dispatch(getCredentials({})).unwrap();
      const res = await dispatch(
        getProducts({ credentials: creds, payload: { id: +productKey } })
      ).unwrap();
      // should not be an array if we retrieve with the productKey
      setDescription(res?.[0]?.cms_description);
      setIcon(res?.[0]?.cms_image);
      closeLoader();
    },
    [dispatch, openLoader, closeLoader]
  );

  useEffect(() => {
    getItemDetails(productKey);
  }, [productKey, getItemDetails]);

  return (
    <Card className="recognition-item text-center">
      <CardBody className="d-flex flex-column align-items-center justify-content-center">
        <CardTitle className="d-flex align-items-center justify-content-center">
          {icon && (
            <img
              className="product_icon"
              src={icon}
              alt="logo"
            />
          )}
          <div
            className="subtitle p-3"
            className="description_text"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </CardTitle>
        <CardSubtitle className="d-flex flex-column align-items-center justify-content-center">
          <div className="devider"></div>
          <p className="subtitle text-primary pt-4">
            Registra il prodotto sul tuo account per poter accedere ai suoi
            contenuti.
          </p>
        </CardSubtitle>
      </CardBody>
    </Card >
  );
};

export default RecognitionItem;
