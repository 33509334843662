import React from "react";
import RecognitionItem from "../../components/MembershipItem/recognitionItem";
import CompleteItem from "../../components/MembershipItem/completeItem";
import DownloadItem from "../../components/MembershipItem/downloadItem";
import { getProductKey } from "../../helpers/user";

const ProductRegistered = () => {

  return (
    <>
      <RecognitionItem productKey={getProductKey()}/>
      <CompleteItem />
      <DownloadItem />
    </>
  );
};

export default ProductRegistered;
