import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../services";

export const getProducts = createAsyncThunk(
  "product/getProducts",
  async ({ credentials, payload }, { rejectWithValue }) => {
    try {
      const response = await services.ProductService.getProducts({ credentials, payload });
      return response?.data?.Data;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  error: {},
  state: "",
  productId: "",
  currentProduct: null,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: {
    /* ---- Get Products ---- */
    [String(getProducts.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(getProducts.fulfilled)]: (state, action) => {
      state.currentUser = action.payload;
      state.state = "success";
    },
    [String(getProducts.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export default productSlice.reducer;
