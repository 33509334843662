import React, { useState } from "react";
import { useField, isFunction, Field, FieldArray } from "formik";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as EyeSlash } from "../../assets/icons/eye-slash.svg";

import "./Form.scss";

const FormikFormGroup = ({ label, groupClass = "", children }) => {
  const { t } = useTranslation();

  return (
    <Form.Group className={`formik-form-group sign__group ${groupClass}`}>
      {label && <Form.Label className="sign__label">{t(label)}</Form.Label>}
      {children}
    </Form.Group>
  );
};

const TextInput = ({
  label,
  inputClass = "sign__input",
  onChange,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField(rest);

  const handleChange = (event) => {
    field.onChange(event);

    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup label={label}>
      <Form.Control
        disabled={disabled}
        className={`${inputClass} ${meta.touched && meta.error ? "error" : ""}`}
        name={field.name}
        value={field.value}
        onChange={handleChange}
        {...rest}
      />
      {meta.touched && meta.error && (
        <Form.Text className="invalid-feedback d-block">
          {t(meta.error)}
        </Form.Text>
      )}
    </FormikFormGroup>
  );
};

const CodeInput = ({
  label,
  inputClass = "sign__input",
  onChange,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField(rest);

  const handleChange = (event) => {
    field.onChange(event);

    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup label={label}>
      <Form.Control
        disabled={disabled}
        className={`${inputClass} ${meta.touched && meta.error ? "error" : ""}`}
        name={field.name}
        value={field.value}
        onChange={handleChange}
        {...rest}
      />
      {meta.touched && meta.error && (
        <Form.Text className="invalid-feedback d-block">{t(meta.error)}</Form.Text>
      )
      }
    </FormikFormGroup >
  );
};

const PasswordInput = ({ label, onChange, ...rest }) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [field, meta] = useField(rest);

  const handleChange = (event) => {
    field.onChange(event);

    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup label={label}>
      <InputGroup>
        <Form.Control
          className={`${meta.touched && meta.error
            ? "error sign__input password_field"
            : "sign__input password_field"
            }`}
          name={field.name}
          value={field.value}
          type={passwordVisible ? "text" : "password"}
          onChange={handleChange}
          {...rest}
        />
        <InputGroup.Text {...rest} onClick={() => setPasswordVisible(!passwordVisible)}>
          {passwordVisible ? (
            <Eye style={{ zIndex: "6", fill: "black" }} />
          ) : (
            <EyeSlash style={{ zIndex: "6", fill: "black" }} />
          )}
        </InputGroup.Text>
        {meta.touched && meta.error && (
          <Form.Text className="invalid-feedback d-block">
            {t(meta.error)}
          </Form.Text>
        )}
      </InputGroup>
    </FormikFormGroup>
  );
};

const SingleSelect = ({
  label,
  emptyText = "select-item",
  inputClass = "sign__select",
  options,
  optionIdPrefix,
  withoutTranslation = false,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField(rest);

  const handleChange = (event) => {
    field.onChange(event);

    if (isFunction(onChange)) onChange(event.target.value);
  };

  return (
    <FormikFormGroup label={label}>
      <Form.Control
        className={`${inputClass}${meta.touched && meta.error ? " error" : ""}${field.value === "" ? " text-gray-light-400" : ""
          }`}
        as="select"
        name={field.name}
        value={field.value}
        onChange={handleChange}
        {...rest}
      >
        <option id={`${optionIdPrefix}option-empty`} value="" disabled>
          {t(emptyText)}
        </option>

        {options.map((item, index) => (
          <option
            key={index}
            id={`${optionIdPrefix}option-${index}`}
            className="text-white"
            value={item.value}
          >
            {withoutTranslation ? item.label : t(item.label)}
          </option>
        ))}
      </Form.Control>
      {meta.touched && meta.error && (
        <Form.Text className="invalid-feedback d-block">
          {t(meta.error)}
        </Form.Text>
      )}
    </FormikFormGroup>
  );
};

const Fields = {
  TextInput,
  PasswordInput,
  SingleSelect,
  CodeInput
};

export default Fields;
